import React from "react"

import styles from "./form.module.scss"

const Form = props => {
  return (
    <div className={`${props.fullwidth ? styles.formAlt : styles.form}`}>
      <div className={styles.formInner}>
        <iframe
          title={props.frameTitle}
          id="pardot-form"
          src={props.formUrl}
          width="100%"
          style={{ minHeight: props.height }}
          type="text/html"
          frameBorder="0"
          allowtransparency="true"
        ></iframe>
      </div>
    </div>
  )
}

export default Form
