import React from "react"
import RichText from "./richText"
import AboutTheAuthor from "./aboutTheAuthor"

import styles from "./post.module.scss"

const Post = props => {
  return (
    <article className={styles.postContent}>
      {props.intro && (
        <div className={styles.postIntro}>
          <RichText blocks={props.intro} />
        </div>
      )}
      {props.content && <RichText blocks={props.content} />}
      {props.author && props.author.bio && (
        <AboutTheAuthor
          author={props.author}
          secondAuthor={props.secondAuthor}
        />
      )}
      {props.form && (
        <div className={styles.postForm}>
          <p>Subscribe to our blog</p>
          <div>
            <iframe
              src={props.form}
              width="100%"
              height="315"
              type="text/html"
              frameBorder="0"
              allowtransparency="true"
            ></iframe>
          </div>
        </div>
      )}
    </article>
  )
}

export default Post
