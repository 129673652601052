import React from "react"

import styles from "./postWrapper.module.scss"

const PostWrapper = ({children, sidebar, formLayout}) => {
  return(
    <div className={formLayout ? `${styles.postWrapper} ${styles.postWrapperForm}` : `${styles.postWrapper}`} data-layout={sidebar ? sidebar : "left"}>
      <div className={styles.post}>
        {children}
      </div>
    </div>
  )
}

export default PostWrapper
