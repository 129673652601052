import React from "react"
import Form from "../components/form"
import Figure from "../components/figure"
import Button from "../components/button"

import styles from "./postSidebarResource.module.scss"

const PostSidebarResource = props => {
  const link = props.resource
    ? props.resource.link || props.resource.existingLink
    : null
  return (
    <aside
      className={
        props.formUrl !== null
          ? `${styles.postSidebar} ${styles.postSidebarForm}`
          : `${styles.postSidebar}`
      }
    >
      {props.formUrl ? (
        <Form frameTitle="Form" height={"900px"} formUrl={props.formUrl} />
      ) : (
        <div className={styles.sidebarThumbnail}>
          <Figure {...props.thumb} />
          {link && link.asset && (
            <Button
              {...props.resource}
              link={link}
              id={`resource-${link.asset.assetId}`}
              newTab
              noFollow
            />
          )}
        </div>
      )}
    </aside>
  )
}

export default PostSidebarResource
