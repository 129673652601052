import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import Hero from "../../components/hero"
import PostWrapper from "../../components/postWrapper"
import Post from "../../components/post"
import PostSidebar from "../../components/postSidebarResource"
import Form from "../../components/form"

export const query = graphql`
  query($slug: String) {
    sanityResource(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      _rawThumbnail
      _rawBody(resolveReferences: { maxDepth: 10 })
      formUrl
      position
      _rawResource(resolveReferences: { maxDepth: 10 })
      seo {
        metaTitle
        canonical
        description
        noIndex
        openGraphImage {
          asset {
            url
          }
        }
      }
    }
  }
`

const ResourceTemplate = props => {
  const page = props.data.sanityResource
  const customTitle = page.seo && page.seo.metaTitle ? page.seo.metaTitle : null
  const description =
    page.seo && page.seo.description ? page.seo.description : null
  const image =
    page.seo &&
    page.seo.openGraphImage &&
    page.seo.openGraphImage.asset !== null
      ? page.seo.openGraphImage.asset.url
      : null

  return (
    <Layout theme="light">
      <Helmet>
        {page.seo && page.seo.noIndex && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title={page.title}
        customTitle={customTitle}
        description={description}
        image={image}
      />
      <Hero heroTitle={page.title} theme="light" graph="angularLine" />
      <PostWrapper
        sidebar={page.position === "Bottom" ? "center" : "right"}
        formLayout={page.formUrl !== null}
      >
        <Post content={page._rawBody} />
        {page.position !== "Bottom" && (
          <PostSidebar
            formUrl={page.formUrl}
            thumb={page._rawThumbnail}
            resource={page._rawResource}
          />
        )}
      </PostWrapper>
      {page.formUrl !== null && page.position === "Bottom" && (
        <div className="grid">
          <div className="gridItem xlarge-offset-1 xlarge-10">
            <Form
              frameTitle={`${page.title} form`}
              height={"900px"}
              formUrl={page.formUrl}
            />
          </div>
        </div>
      )}
    </Layout>
  )
}

export default ResourceTemplate
