import React from "react"
import Figure from "./figure"
import SocialIcon from "./socialIcon"

import styles from "./post.module.scss"
import cx from "classnames"

const AboutTheAuthor = ({ author, secondAuthor }) => {
  const renderAuthor = props => {
    return (
      <div
        className={cx(
          styles.postAuthor,
          secondAuthor && styles.secondAuthorWrapper
        )}
      >
        {props?.image && <Figure {...props?.image} />}
        {props?.bio && (
          <div className={styles.postAuthorInner}>
            <p>{props.bio}</p>
            {props.social && (
              <ul>
                {props.social.map(platform => (
                  <li key={platform._key}>
                    <a
                      href={platform.url}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      <span>Link to {platform.platform}</span>
                      <SocialIcon type={platform.platform} />
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={styles.postAuthorWrapper}>
      <h4>About the author{secondAuthor && "s"}</h4>
      {author && renderAuthor(author)}
      {secondAuthor && renderAuthor(secondAuthor)}
    </div>
  )
}

export default AboutTheAuthor
